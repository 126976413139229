import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48bf852d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-list" }
const _hoisted_2 = ["onClick"]

import letterArr from "@/constant/letterArr"; // 26个英文字母
import { ref, watch } from "vue";
import { useRecommend } from "@/pinia/api/useRecommend.js";

export default {
  setup(__props, { expose }) {

const state = useRecommend();
const currentIndex = ref(-1);
const getKeywordsData = (keywords, index) => {
  // 这里可以拿到 当前点击的 关键词，可以进行逻辑重置 逻辑搜索
  currentIndex.value = index;
  state.sort(keywords);
};

expose({
  setCurIndex() {
    currentIndex.value = -1;
  },
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(letterArr), (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(index === currentIndex.value ? 'active' : ''),
          onClick: $event => (getKeywordsData(item, index))
        }, _toDisplayString(item.toUpperCase()), 11, _hoisted_2))
      }), 128))
    ])
  ]))
}
}

}