

import
http
    from '../axios'


// 初始化数据
export const getRecommendDta = (params) => {
    return http.post('/company',
        params)
}

